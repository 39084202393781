



















































































import SiteHeading from '@/components/SiteHeading.vue'
import SiteSection from '@/components/SiteSection.vue'
import BaseBox from '@/components/_base/BaseBox.vue'
import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from '@vue/composition-api'
import { gsap } from 'gsap'

export default defineComponent({
  name: 'SiteSectionTheFinePrint',

  components: {
    SiteSection,
    SiteHeading,
  },

  setup() {
    const content = ref<InstanceType<typeof BaseBox> | null>(null)

    const tl = gsap.timeline({
      paused: true,
      delay: 1,
    })

    onMounted(() => {
      const contentEl = content.value?.$el

      if (!contentEl) return

      tl.fromTo(
        contentEl.children,
        {
          y: 50,
          opacity: 0,
        },
        {
          ease: 'expo.out',
          y: 0,
          rotationX: 0,
          opacity: 1,
          duration: 1.4,
          stagger: 0.2,
        }
      )
    })

    onUnmounted(() => {
      tl.clear()
    })

    function visibilityChanged(isVisible: boolean) {
      if (!isVisible) return
      tl.play()
    }

    return {
      content,
      visibilityChanged,
    }
  },
})
