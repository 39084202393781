















































import BaseText from '@/components/_base/BaseText.vue'
import BaseLink from '@/components/_base/BaseLink.vue'
import SiteSection from '@/components/SiteSection.vue'
import SiteHeading from '@/components/SiteHeading.vue'
import appConfig from '@/app.config.json'
import { gsap } from 'gsap'
import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from '@vue/composition-api'
import TimeLine, { TimeLineEventConfig } from './TimeLine.vue'

export default defineComponent({
  name: 'SiteSectionTheItinerary',

  components: {
    SiteSection,
    SiteHeading,
    TimeLine,
  },

  setup() {
    const directionsUrl = 'https://goo.gl/maps/eev9eefurNaTWyPVA'
    const titleOne = ref<InstanceType<typeof BaseText> | null>(null)
    const titleTwo = ref<InstanceType<typeof BaseText> | null>(null)
    const titleThree = ref<InstanceType<typeof BaseText> | null>(null)
    const cta = ref<InstanceType<typeof BaseLink> | null>(null)
    const { year, month, day } = appConfig.wedding.date
    const timelineEvents: TimeLineEventConfig[] = [
      {
        date: new Date(year, month, day, 18, 30),
        label: 'Misa',
        icon: 'rings',
      },
      {
        date: new Date(year, month, day, 20, 35),
        label: 'Cena',
        icon: 'party',
        inverse: true,
      },
      {
        date: new Date(year, month, day, 21, 30),
        label: 'Fiesta',
        icon: 'feast',
      },
      {
        date: new Date(year, month, day, 3),
        label: 'Salida',
        icon: 'good-bye',
        inverse: true,
      },
    ]

    const tl = gsap.timeline({
      paused: true,
      delay: 1,
    })

    onMounted(() => {
      const titleOneEl = titleOne.value?.$el
      const titleTwoEl = titleTwo.value?.$el
      const titleThreeEl = titleThree.value?.$el
      const ctaEl = cta.value?.$el

      if (!titleOneEl || !titleTwoEl || !titleThreeEl || !ctaEl) return

      const elements = [titleOneEl, titleTwoEl, titleThreeEl, ctaEl]

      tl.fromTo(
        elements,
        {
          y: 100,
          rotationX: -90,
        },
        {
          ease: 'expo.out',
          y: 0,
          rotationX: 0,
          duration: 1.5,
          stagger: 0.2,
        }
      )
    })

    onUnmounted(() => {
      tl.clear()
    })

    function visibilityChanged(isVisible: boolean) {
      if (!isVisible) return
      tl.play()
    }

    return {
      directionsUrl,
      timelineEvents,
      titleOne,
      titleTwo,
      titleThree,
      cta,
      visibilityChanged,
    }
  },
})
