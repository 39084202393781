import { AxiosInstance } from 'axios'
import { api } from '../../client'
import { GuidIdTableType, serviceUrl } from '../../utils'

const controller = serviceUrl('invitation')

function createRsvpInvitationService(client: AxiosInstance) {
  return {
    insert(data: InvitationInsertModel) {
      return client.post<InvitationViewModel>(controller.action('insert'), data)
    },
  }
}

export const rsvpInvitationService = createRsvpInvitationService(api.rsvp)

export interface InvitationInsertModel {
  clientId: string
  languageId: string
  clientName: string
  title: string
  toEmail: string
  name: string
  phone: string
  email: string
  guest: number
  arriveDate: Date
  isAttending: boolean
  mealPreference: string
  message: string
  drinkList: GuidIdTableType[]
}

export interface InvitationViewModel {
  invitationId: string
  invitationKey: number
  drink: string
}
