

















































































































import SiteSection from '@/components/SiteSection.vue'
import SiteShape from '@/components/SiteShape.vue'
import BaseBox from '@/components/_base/BaseBox.vue'
import BaseText from '@/components/_base/BaseText.vue'
import { useCountdown } from '@/composition/countdown'
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { gsap } from 'gsap'
import appConfig from '@/app.config.json'

export default defineComponent({
  name: 'SiteSectionHero',

  components: {
    SiteSection,
    SiteShape,
  },

  emits: {
    'animation-end': null,
  },

  setup(props, context) {
    const { date: weddingDate, bride, groom } = appConfig.wedding
    const { year, month, day } = weddingDate

    const { duration, durationInDays } = useCountdown({
      endDate: new Date(year, month, day),
    })

    const titleOne = ref<InstanceType<typeof BaseText> | null>(null)
    const titleTwo = ref<InstanceType<typeof BaseText> | null>(null)
    const titleThree = ref<InstanceType<typeof BaseText> | null>(null)
    const countdown = ref<InstanceType<typeof BaseBox> | null>(null)
    const background = ref<InstanceType<typeof BaseBox> | null>(null)
    const shapes = ref<InstanceType<typeof BaseBox> | null>(null)

    onMounted(() => {
      const titleOneEl = titleOne.value?.$el
      const titleTwoEl = titleTwo.value?.$el
      const titleThreeEl = titleThree.value?.$el
      const countdownEl = countdown.value?.$el
      const backgroundEl = background.value?.$el
      const shapesEl = shapes.value?.$el

      if (
        !titleOneEl ||
        !titleTwoEl ||
        !titleThreeEl ||
        !countdownEl ||
        !backgroundEl ||
        !shapesEl
      )
        return

      const titleElements = [titleOneEl, titleTwoEl, titleThreeEl]

      const tl = gsap.timeline({
        delay: 0.5,
      })

      gsap.set(shapesEl, {
        opacity: 0,
      })

      gsap.set(countdownEl, {
        opacity: 0,
      })

      tl.to(backgroundEl, {
        ease: 'expo.out',
        yPercent: -100,
        duration: 1,
      })

      tl.fromTo(
        titleElements,
        {
          y: 200,
          rotationX: -90,
        },
        {
          ease: 'expo.out',
          y: 0,
          rotationX: 0,
          duration: 1,
          stagger: 0.2,
        }
      )

      tl.to(countdownEl, {
        ease: 'expo.in',
        opacity: 1,
        delay: -1,
        duration: 1,
      })

      tl.fromTo(
        countdownEl.children,
        {
          y: 50,
          opacity: 0,
        },
        {
          ease: 'expo.out',
          y: 0,
          opacity: 1,
          duration: 1,
          stagger: 0.2,
        }
      )

      tl.to(shapesEl, {
        ease: 'expo.in',
        opacity: 1,
        delay: -1.25,
        duration: 1,
        onComplete: () => {
          context.emit('animation-end')
        },
      })
    })

    return {
      bride,
      groom,
      duration,
      durationInDays,
      countdown,
      background,
      titleOne,
      titleTwo,
      titleThree,
      shapes,
    }
  },
})
