






































import { currentLanguage } from '@/utils/language'
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'TimeLineEvent',

  props: {
    date: {
      type: Date,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    iconSource: {
      type: String,
      default: 'custom',
    },
    inverse: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const formattedDate = computed(() =>
      props.date.toLocaleTimeString(currentLanguage.value.locale, {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
      })
    )

    return {
      formattedDate,
    }
  },
})
