

























































































































import BaseBox from '@/components/_base/BaseBox.vue'
import SiteSection from '@/components/SiteSection.vue'
import SiteHeading from '@/components/SiteHeading.vue'
import communique from '@/notification'
import i18n from '@/setup/i18n'
import { gsap } from 'gsap'
import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from '@vue/composition-api'

interface GiftOptionItem {
  title: string
  link: string
  icon?: string
  iconSource?: string
  i18nPath?: string
  class: string
}

export default defineComponent({
  name: 'SiteSectionTheGift',

  components: {
    SiteSection,
    SiteHeading,
  },

  setup() {
    const bankInfo = {
      clabe: '0123 2001 5305 571421',
      cardNumber: '4152 3137 8383 7342',
    }

    const clabe = bankInfo.clabe.replace(/\s+/g, '')
    const cardNumber = bankInfo.cardNumber.replace(/\s+/g, '')

    const items: GiftOptionItem[] = [
      {
        title: 'Liverpool',
        icon: 'gifts',
        link: 'https://mesaderegalos.liverpool.com.mx/milistaderegalos/51399507',
        i18nPath: 'ctaOne',
        class: '',
      },
      {
        title: 'Efectivo',
        icon: 'envelope',
        iconSource: 'custom',
        link: '',
        class: '',
      },
    ]

    const content = ref<InstanceType<typeof BaseBox> | null>(null)

    const tl = gsap.timeline({
      paused: true,
      delay: 1,
    })

    onMounted(() => {
      const contentEl = content.value?.$el

      if (!contentEl) return

      tl.fromTo(
        contentEl.children,
        {
          y: 50,
          opacity: 0,
        },
        {
          ease: 'expo.out',
          y: 0,
          rotationX: 0,
          opacity: 1,
          duration: 1.4,
          stagger: 0.2,
        }
      )
    })

    onUnmounted(() => {
      tl.clear()
    })

    function visibilityChanged(isVisible: boolean) {
      if (!isVisible) return
      tl.play()
    }

    const canCopyToClipboard = Boolean(navigator.clipboard)

    async function copyToClipboard(text: string) {
      try {
        await navigator.clipboard.writeText(text)

        communique.dispatch({
          variant: 'success',
          message: i18n.t('copyToClipboardSuccess') as string,
        })
      } catch (error) {
        communique.dispatch({
          variant: 'error',
          message: i18n.t('copyToClipboardError') as string,
        })
      }
    }

    return {
      items,
      content,
      visibilityChanged,
      canCopyToClipboard,
      copyToClipboard,
      bankInfo,
      clabe,
      cardNumber,
    }
  },
})
