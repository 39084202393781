var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseBox',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.visibilityChanged,
    once: true,
    intersection: {
      threshold: 0.3,
    },
  }),expression:"{\n    callback: visibilityChanged,\n    once: true,\n    intersection: {\n      threshold: 0.3,\n    },\n  }"}],staticClass:"grid gap-3"},[_c('BaseHeading',{staticClass:"text-xl text-center lg:text-left tracking-widest uppercase",attrs:{"level":2}},[_c('BaseText',{ref:"title",staticClass:"block"},[_vm._t("default")],2)],1),_c('BaseBox',{ref:"shape",class:[
      'w-12 h-2 origin-left m-auto lg:m-0',
      _vm.variant === 'primary' ? 'bg-primary ' : 'bg-secondary' ]})],1)}
var staticRenderFns = []

export { render, staticRenderFns }