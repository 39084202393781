



















































import appConfig from '@/app.config.json'
import SiteHeading from '@/components/SiteHeading.vue'
import SiteSection from '@/components/SiteSection.vue'
import BaseText from '@/components/_base/BaseText.vue'
import { currentLanguage } from '@/utils/language'
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from '@vue/composition-api'
import { gsap } from 'gsap'

export default defineComponent({
  name: 'SiteSectionTheDate',

  components: {
    SiteSection,
    SiteHeading,
  },

  setup() {
    const { year, month, day } = appConfig.wedding.date

    const weddingDate = new Date(year, month, day)

    const weekdayLabel = computed(() =>
      weddingDate.toLocaleDateString(currentLanguage.value.locale, {
        weekday: 'long',
      })
    )

    const monthAndDayLabel = computed(() =>
      weddingDate.toLocaleDateString(currentLanguage.value.locale, {
        month: 'long',
        day: '2-digit',
      })
    )

    const yearLabel = computed(() =>
      weddingDate.toLocaleDateString(currentLanguage.value.locale, {
        year: 'numeric',
      })
    )

    const titleOne = ref<InstanceType<typeof BaseText> | null>(null)
    const titleTwo = ref<InstanceType<typeof BaseText> | null>(null)
    const titleThree = ref<InstanceType<typeof BaseText> | null>(null)

    const tl = gsap.timeline({
      paused: true,
      delay: 1,
    })

    onMounted(() => {
      const titleOneEl = titleOne.value?.$el
      const titleTwoEl = titleTwo.value?.$el
      const titleThreeEl = titleThree.value?.$el

      if (!titleOneEl || !titleTwoEl || !titleThreeEl) return

      const titleElements = [titleOneEl, titleTwoEl, titleThreeEl]

      tl.fromTo(
        titleElements,
        {
          y: 100,
          rotationX: -90,
        },
        {
          ease: 'expo.out',
          y: 0,
          rotationX: 0,
          duration: 1.5,
          stagger: 0.2,
        }
      )
    })

    onUnmounted(() => {
      tl.clear()
    })

    function visibilityChanged(isVisible: boolean) {
      if (!isVisible) return
      tl.play()
    }

    return {
      weekdayLabel,
      monthAndDayLabel,
      yearLabel,
      titleOne,
      titleTwo,
      titleThree,
      visibilityChanged,
    }
  },
})
