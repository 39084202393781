import { AxiosInstance } from 'axios'
import { api } from '../../client'
import { PaginationList, PaginationSelectModel, serviceUrl } from '../../utils'

const controller = serviceUrl('drink')

function createRsvpDrinkService(client: AxiosInstance) {
  return {
    get(params: DrinkModel) {
      return client.get<PaginationList<DrinkViewModel>>(
        controller.action('get'),
        {
          params,
        }
      )
    },
  }
}

export const rsvpDrinkService = createRsvpDrinkService(api.rsvp)

export interface DrinkModel extends PaginationSelectModel {
  languageId: string
  clientId: string
}

export interface DrinkViewModel {
  drinkId: string
  drinkKey: number
  name: string
  description: string
}
