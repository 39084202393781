





























































































































import appConfig from '@/app.config.json'
import SiteSectionHero from '@/components/SiteSectionHero.vue'
import SiteSectionRsvp from '@/components/SiteSectionRsvp.vue'
import SiteSectionTheDate from '@/components/SiteSectionTheDate.vue'
import SiteSectionTheFinePrint from '@/components/SiteSectionTheFinePrint.vue'
import SiteSectionTheGift from '@/components/SiteSectionTheGift.vue'
import SiteSectionTheInvite from '@/components/SiteSectionTheInvite.vue'
import SiteSectionTheItinerary from '@/components/SiteSectionTheItinerary.vue'
import SiteSectionTheMass from '@/components/SiteSectionTheMass.vue'
import SiteSectionTheParty from '@/components/SiteSectionTheParty.vue'
import BaseBox from '@/components/_base/BaseBox.vue'
import { currentRoute } from '@/router'
import { defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default defineComponent({
  name: 'Home',

  components: {
    SiteSectionHero,
    SiteSectionTheDate,
    SiteSectionTheInvite,
    SiteSectionTheMass,
    SiteSectionTheParty,
    SiteSectionTheItinerary,
    SiteSectionTheGift,
    SiteSectionTheFinePrint,
    SiteSectionRsvp,
  },

  setup() {
    const content = ref<InstanceType<typeof BaseBox> | null>(null)
    const banner = ref<InstanceType<typeof BaseBox> | null>(null)
    const isShowingBanner = ref(true)

    const stopWatchingHash = watch(
      () => currentRoute.value,
      (newValue, oldValue) => {
        if (
          oldValue &&
          newValue &&
          oldValue.name === newValue.name &&
          oldValue.hash !== newValue.hash
        ) {
          isShowingBanner.value = false
          stopWatchingHash()
        }
      }
    )

    const tl = gsap.timeline({
      paused: true,
    })

    onMounted(() => {
      const contentEl = content.value?.$el
      const bannerEl = banner.value?.$el

      if (!contentEl || !bannerEl) return

      const pinnedChildren = [...Array.from(contentEl.children)]

      pinnedChildren.forEach((item) => {
        ScrollTrigger.create({
          trigger: item,
          start: 'top top',
          pin: true,
          pinSpacing: false,
        })
      })

      gsap.set(bannerEl, {
        y: 200,
        opacity: 0,
      })

      tl.to(bannerEl, {
        ease: 'expo.out',
        y: 0,
        opacity: 1,
        duration: 1.25,
      })
    })

    function playAnimation() {
      tl.play()
    }

    return {
      content,
      banner,
      isShowingBanner,
      playAnimation,
      bride: appConfig.wedding.bride,
      groom: appConfig.wedding.groom,
    }
  },
})
