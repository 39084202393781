import { computed, onMounted, onUnmounted, ref } from '@vue/composition-api'
import { differenceInDays, intervalToDuration } from 'date-fns'

export interface CountdownOptions {
  endDate: Date
  withHooks?: boolean
}

export function useCountdown({ endDate, withHooks = true }: CountdownOptions) {
  const today = new Date()
  const timer = ref<number | null>(null)
  const startDate = ref(new Date(today))
  const isPastDue = computed(() => today >= endDate)

  const duration = computed(() => {
    return intervalToDuration({
      start: isPastDue.value ? 0 : startDate.value,
      end: isPastDue.value ? 0 : endDate,
    })
  })

  const durationInDays = computed(() =>
    differenceInDays(endDate, startDate.value)
  )

  function updateStartDate() {
    startDate.value = new Date()
  }

  function startInterval() {
    timer.value = window.setInterval(updateStartDate, 1000)
  }

  function stopInterval() {
    if (!timer.value) return
    window.clearInterval(timer.value)
  }

  if (withHooks) {
    onMounted(() => {
      if (isPastDue.value) return
      startInterval()
    })

    onUnmounted(() => {
      stopInterval()
    })
  }

  return {
    duration,
    durationInDays,
    startInterval,
    stopInterval,
  }
}
