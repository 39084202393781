





























import BaseBox from '@/components/_base/BaseBox.vue'
import BaseHeading from '@/components/_base/BaseHeading.vue'
import {
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  ref,
} from '@vue/composition-api'
import { gsap } from 'gsap'

type SiteHeadingVariant = 'primary' | 'secondary'

export default defineComponent({
  name: 'SiteHeading',

  props: {
    variant: {
      type: String as PropType<SiteHeadingVariant>,
      default: 'primary' as SiteHeadingVariant,
    },
  },

  setup() {
    const title = ref<InstanceType<typeof BaseHeading> | null>(null)
    const shape = ref<InstanceType<typeof BaseBox> | null>(null)

    const tl = gsap.timeline({
      paused: true,
    })

    onMounted(() => {
      const titleEl = title.value?.$el
      const shapeEl = shape.value?.$el

      if (!titleEl || !shapeEl) return

      gsap.set(shapeEl, {
        scaleX: 0,
      })

      tl.fromTo(
        titleEl,
        {
          y: 50,
          rotationX: -90,
        },
        {
          ease: 'expo.out',
          y: 0,
          rotationX: 0,
          delay: 0.5,
          duration: 1.5,
        }
      )

      tl.to(shapeEl, {
        ease: 'expo.out',
        scaleX: 1,
        delay: -1.25,
        duration: 1.5,
      })
    })

    onUnmounted(() => {
      tl.clear()
    })

    function visibilityChanged(isVisible: boolean) {
      if (!isVisible) return
      tl.play()
    }

    return {
      title,
      shape,
      visibilityChanged,
    }
  },
})
