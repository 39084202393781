
































import {
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  ref,
} from '@vue/composition-api'
import { gsap } from 'gsap'
import TimeLineEvent from './TimeLineEvent.vue'
import BaseBox from './_base/BaseBox.vue'

export interface TimeLineEventConfig {
  date: Date
  label: string
  icon: string
  inverse?: boolean
}

export default defineComponent({
  name: 'TimeLine',
  components: {
    TimeLineEvent,
  },
  props: {
    events: {
      type: Array as PropType<TimeLineEventConfig[]>,
      default: () => [],
    },
  },

  setup() {
    const eventTimeline = ref<InstanceType<typeof BaseBox> | null>(null)

    const tl = gsap.timeline({
      paused: true,
    })

    onMounted(() => {
      const eventTimelineEl = eventTimeline.value?.$el

      if (!eventTimelineEl) return

      tl.fromTo(
        eventTimelineEl.children,
        {
          y: 50,
          opacity: 0,
        },
        {
          ease: 'expo.out',
          y: 0,
          opacity: 1,
          duration: 1.5,
          stagger: 0.2,
        }
      )
    })

    onUnmounted(() => {
      tl.clear()
    })

    function visibilityChanged(isVisible: boolean) {
      if (!isVisible) return
      tl.play()
    }

    return {
      eventTimeline,
      visibilityChanged,
    }
  },
})
