













































































import BaseText from '@/components/_base/BaseText.vue'
import BaseLink from '@/components/_base/BaseLink.vue'
import SiteSection from '@/components/SiteSection.vue'
import SiteHeading from '@/components/SiteHeading.vue'
import { gsap } from 'gsap'
import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from '@vue/composition-api'

export default defineComponent({
  name: 'SiteSectionTheMass',

  components: {
    SiteSection,
    SiteHeading,
  },

  setup() {
    const directionsUrl = 'https://maps.app.goo.gl/1a4NWD65NSGUas4E6'
    const titleOne = ref<InstanceType<typeof BaseText> | null>(null)
    const titleTwo = ref<InstanceType<typeof BaseText> | null>(null)
    const titleThree = ref<InstanceType<typeof BaseText> | null>(null)
    const cta = ref<InstanceType<typeof BaseLink> | null>(null)

    const tl = gsap.timeline({
      paused: true,
      delay: 1,
    })

    onMounted(() => {
      const titleOneEl = titleOne.value?.$el
      const titleTwoEl = titleTwo.value?.$el
      const titleThreeEl = titleThree.value?.$el
      const ctaEl = cta.value?.$el

      if (!titleOneEl || !titleTwoEl || !titleThreeEl || !ctaEl) return

      const elements = [titleOneEl, titleTwoEl, titleThreeEl, ctaEl]

      tl.fromTo(
        elements,
        {
          y: 100,
          rotationX: -90,
        },
        {
          ease: 'expo.out',
          y: 0,
          rotationX: 0,
          duration: 1.5,
          stagger: 0.2,
        }
      )
    })

    onUnmounted(() => {
      tl.clear()
    })

    function visibilityChanged(isVisible: boolean) {
      if (!isVisible) return
      tl.play()
    }

    return {
      directionsUrl,
      titleOne,
      titleTwo,
      titleThree,
      cta,
      visibilityChanged,
    }
  },
})
